<template>
    <component :is="detailsComponentMap[detailsComponentKey]" :product-id="productId"></component>
</template>


<script setup lang="ts">
    import CruiseDetailsComponent from "components/products/details/cruise-details.vue";
    import HotelDetailsComponent from "components/products/details/hotel-details.vue";
    import TourDetailsComponent from "components/products/details/tour-details.vue";
    import AdvisorDetailsComponent from "components/advisor/advisor-details.vue";
    import { ProductType } from "interfaces/enums";
    import { PropType } from "vue";
    import type { Component } from "vue";   

    const props = defineProps({
        catalogType: {
            type: String as PropType<ProductType>,
            default: undefined
        },
        productId: {
            type: Number,
            default: undefined
        }
    }); 
    // Map of component names to actual components
    const detailsComponentMap: Record<string, Component> = {
        "cruise-details-component": CruiseDetailsComponent,
        "hotel-details-component": HotelDetailsComponent,
        "tour-details-component": TourDetailsComponent,
        "advisor-details-component": AdvisorDetailsComponent
    };

    const detailsComponentKey = props.catalogType.toLowerCase().slice(0, -1) + "-details-component";
</script>
